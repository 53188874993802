import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#katar"> &gt; Poradnik(Katar)</a>
            <a
              href="/poradnik/katar/co_to_jest_zapalenie_zatok/"
              className="sel"
            >
              {" "}
              &gt; Co to jest zapalenie zatok?
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_118647259.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Co to jest zapalenie zatok?</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    <b>
                      Ostre zapalenie zatok to jedna z dziesięciu najczęściej
                      występujących dolegliwości. Zwykle pojawia się wiosną i
                      jesienią, w wyniku infekcji wirusowej. Początkowe objawy
                      to ból w okolicy czoła i nosa, nasilająca się niedrożność
                      górnych dróg oddechowych i podwyższona temperatura. Ostre
                      zapalenie zatok łatwo pomylić z&nbsp;przeziębieniem,
                      dlatego warto wiedzieć, jak je od siebie odróżnić. &nbsp;
                    </b>
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    <a href="/slowniczek/#zatoki_przynosowe" target="_blank">
                      Zatoki przynosowe
                    </a>{" "}
                    (przestrzenie zlokalizowane w obrębie czaszki) znajdują się
                    pomiędzy jamą nosową a kośćmi, od których przyjmują nazwy
                    (zatoki czołowe, sitowe, klinowe, szczękowe). Pokryte są{" "}
                    <a href="/slowniczek/#blona_sluzowa_nosa" target="_blank">
                      błoną śluzową
                    </a>
                    ,&nbsp;która produkuje{" "}
                    <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                      wydzielinę
                    </a>
                    . Wytwarzany śluz odpowiada za ochronę przed zakażeniem
                    bakteryjnym. Zatoki przynosowe pełnią m.in. funkcje:
                    ochronną (chronią zarówno przed urazami mechanicznymi, jak
                    i&nbsp;temperaturą), oddechową (nawilżają), a także wpływają
                    na brzmienie naszego głosu. Nieleczony katar,{" "}
                    <a href="/slownieczek/#przeziebienie" target="_blank">
                      przeziębienie
                    </a>
                    ,&nbsp; a w niektórych przypadkach alergie, powodują blokadę
                    ujścia zatok, wypełnienie ich nadmierną ilością śluzu, a w
                    konsekwencji nawet{" "}
                    <a
                      href="/slowniczek/#zapalenia_blony_sluzowej_nosa"
                      target="_blank"
                    >
                      zapalenie zatok
                    </a>
                    . Innymi czynnikami, sprzyjającym zapaleniu zatok są
                    uwarunkowania anatomiczne (np. skrzywienie przegrody nosa,
                    przerost małżowiny, guzy zaburzające wentylację zatok) lub
                    przebyte infekcje górnych dróg oddechowych.&nbsp;
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                  <img
                    alt="Objawy zapalenia zatok - dowiedz się jak leczyć zatoki. SUDAFED®"
                    src="/web/kcfinder/uploads/images/shutterstock_243107530.jpg"
                    style={{
                      float: "right",
                      margin: "5px 0px 10px 10px",
                      border: "2px solid rgb(255, 255, 255)",
                      width: "360px",
                      height: "230px",
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Jak rozpoznać ostre zapalenie zatok? Pierwsze objawy są
                  związane z rodzajem zapalenia. Jeśli pojawia się silny ucisk u
                  nasady nosa i ból za okiem, wówczas mamy do czynienia z
                  zapaleniem zatok szczękowych. Gdy ból promieniuje do zębów,
                  uszu i okolicy skroniowo-czołowej, zapalenie dotyka zatok
                  szczękowych. Dolegliwości zlokalizowane nad łukiem brwiowym i
                  na czole sygnalizują zapalenie zatok czołowych. Natomiast ból
                  w okolicy tylnej części czaszki oznacza zapalenie zatok
                  klinowych. Co istotne, we wszystkich rodzajach zapalenia zatok
                  objawy nasilają się o poranku, podczas wstawania z łóżka oraz
                  przy pochylaniu się.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Do typowych symptomów zapalenia zatok zaliczamy: obfitą,
                    śluzowo-ropną{" "}
                    <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                      wydzielinę
                    </a>{" "}
                    z&nbsp;nosa, przewlekły katar,{" "}
                    <a
                      href="/slowniczek/#obrzek_blony_sluzowej"
                      target="_blank"
                    >
                      obrzęk błony śluzowej
                    </a>
                    , podwyższoną temperaturę (około 38°C), zaburzenie węchu i
                    smaku, ogólne osłabienie, zmęczenie, kaszel i przykry zapach
                    z ust.&nbsp;
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Okres trwania dolegliwości decyduje o rodzaju zapalenie
                    zatok. Tak więc zapalenie można podzielić na:
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <ul>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span className="s1">
                      <b>ostre zapalenie zatok</b> czyli infekcję, która zaczyna
                      się nagle i trwa do czterech tygodni. Gdy jest leczona
                      prawidłowo nie powoduje powikłań (np. zapalenia ucha).
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span className="s1">
                      <b>nawracające ostre zapalenie zatok</b>, a więc
                      występowanie co najmniej czterech epizodów zapalenia zatok
                      w ciągu roku, które trwają od 7 do 10 dni i nie są
                      jednocześnie przewlekłym zapaleniem zatok.
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span className="s1">
                      <b>zapalenie podostre</b> trwające od 4 do 12 tygodni. Po
                      takim czasie choroby nie dochodzi do zmian przetrwałych i
                      możliwa jest regeneracja błony śluzowej zatok.
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span className="s1">
                      <b>przewlekłe zapalenie zatok </b>będące efektem
                      nieprawidłowo leczonego ostrego (często nawracającego) lub
                      podostrego zapalenia zatok. W tym przypadku choroba trwa
                      krócej niż 12 tygodni.
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span className="s1">
                      <b>zaostrzone przewlekłe zapalenie zatok</b> czyli nagłe
                      pogorszenie przewlekłego zapalenia zatok. Wówczas objawy
                      ulegają zaostrzeniu, podnosi się gorączka i nasila
                      ból.&nbsp;
                    </span>
                  </li>
                </ul>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <div>&nbsp;</div>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Z pomocą mogą nam przyjść odpowiednie leki. By udrożnić nos
                    i zatoki, warto sięgnąć po{" "}
                    <a href="/produkty/sudafed_tabletki/" target="_blank">
                      tabletki Sudafed<sup>®</sup>
                    </a>{" "}
                    lub aerozole, np.{" "}
                    <a href="/produkty/sudafed_xylospray/" target="_blank">
                      Sudafed<sup>®</sup> Xylospray
                    </a>{" "}
                    lub{" "}
                    <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                      Sudafed<sup>®</sup> Xylospray HA
                    </a>
                    . Tabletki, dzięki zawartości{" "}
                    <a href="/slowniczek/#pseudoefedryna" target="_blank">
                      pseudoefedryny
                    </a>
                    , eliminują uczucie ucisku w głowie i zatokach<sup>1</sup>{" "}
                    spowodowane obrzękiem błony śluzowej nosa i nagromadzoną w
                    górnych drogach oddechowych wydzieliną. Natomiast areozole{" "}
                    <a href="/produkty/sudafed_xylospray/" target="_blank">
                      Sudafed<sup>®</sup> XyloSpray
                    </a>{" "}
                    i{" "}
                    <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                      Sudafed<sup>®</sup> Xylospray HA
                    </a>
                    , dzięki zawartości{" "}
                    <a href="/slowniczek/#ksylometazolina" target="_blank">
                      ksylometazoliny
                    </a>{" "}
                    obkurczają naczynia krwionośne w błonie śluzowej nosa,
                    dzięki czemu zmniejsza się jej obrzęk.
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    W wielu przypadkach stany zapalne zatok rozwijają się w
                    następstwie przebytego{" "}
                    <a href="/slowniczek/#przeziebienie" target="_blank">
                      przeziębienia
                    </a>
                    . Dlatego tak ważne jest, by zapobiegać infekcjom wirusowym
                    oraz dbać o&nbsp;prawidłowe funkcjonowanie naszego
                    organizmu. Lekarze zalecają, by spędzać czas na świeżym
                    powietrzu każdego dnia, przez cały rok, bowiem wówczas
                    hartujemy nasz organizm. Warto więc zadbać o codzienną
                    aktywność poza domem. Można wybrać się na spacer,
                    przejażdżkę rowerową czy jogging. Dzięki tzw. kąpielom
                    powietrznym (które dają najlepsze efekty, gdy świeci słońce)
                    zwiększamy odporność na wiele chorób. &nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Lepszej odporności organizmu sprzyja również prawidłowa
                    dieta bogata w witaminy i ważne dla naszego organizmu
                    pierwiastki. Obowiązkowo w naszym jadłospisie powinny
                    znaleźć się: owoce i warzywa (zawierające m.in. witaminę A,
                    C, Beta-karoten, a także fosfor i potas), ryby (źródło
                    cynku), czosnek (posiadający właściwości bakteriobójcze),
                    cebula (chroniąca przed infekcjami) i zioła (np. herbatki
                    ziołowe z rumianku, malin czy czarnego bzu). Te produkty
                    zwiększają naszą odporność i pomagają zapobiec
                    przeziębieniu.&nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    W okresie jesienno-zimowym powinniśmy unikać dużych skupisk
                    ludzi, a w przypadku, gdy dopadnie nas katar zastosować leki
                    odblokowujące, udrażniające nos i zatoki po to, by uniknąć
                    zalegania śluzu w zatokach. &nbsp; &nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    1 Dotyczy uczucia ucisku w zatokach spowodowanego
                    nagromadzoną wydzieliną i obrzękiem błony śluzowej nosa.
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">Bibliografia:</span>
                  </span>
                </p>
                <ol className="ol1">
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px",
                      }}
                    >
                      <span className="s1">
                        <i>
                          Choroby ucha, nosa i gardła z chirurgią głowy i szyi,{" "}
                        </i>
                        opracowanie zbiorowe, Wrocław,{" "}
                        <a href="http://www.empik.com/szukaj/produkt?publisherFacet=Wydawnictwo+Medyczne+Urban+%252526+Partner">
                          Wydawnictwo&nbsp;Medyczne&nbsp;Urban&nbsp;&amp;&nbsp;Partner
                        </a>
                        , 2012.
                      </span>
                    </span>
                  </li>
                </ol>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg")',
                    }}
                  />
                  <span className="title">
                    Jak prawidłowo oczyszczać nos podczas kataru?
                  </span>
                </a>
                <a href="/poradnik/katar/poznaj_rodzaje_kataru" className="box">
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg")',
                    }}
                  />
                  <span className="title">Poznaj rodzaje kataru</span>
                </a>
                <a
                  href="/poradnik/katar/podejmij_szybka_walke_z_katarem"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_152527238.jpg")',
                    }}
                  />
                  <span className="title">Podejmij szybką walkę z katarem</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
